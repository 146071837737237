//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data(){
        return {
            loading: false,
            TableLoading:false,
            truckID:'',
            server_errors: {
              truck_id: '',
              phone:''
          },
          truck:{},
            truck_edit:{},
            trucks:[],
            headers: [
                {
                    text: "ເລກລະຫັດລົດ",
                    align: "start",
                    sortable: false,
                    value: "truck_number",
                  },
                  {
                      text: "ເລກທະບຽນ",
                      align: "start",
                      sortable: false,
                      value: "license_plate",
                    },
                  { text: "ເບີໂທ", value: "phone" },
                  { text: "Created", value: "created_at" },
                  { text: "Actions", value: "actions", sortable: false },
              ],
              idRules: [
                v => !!v || 'Truck ID is required',
                v => (v && v.length >= 2) || 'Truck ID must be more than 5 characters',
              ],
              phoneRules: [
                (v) => !!v || "Phone is required",
                v => (v && v.length >= 4 && v.length <= 11) || 'Phone number must be  4 - 11 numbers',
              ],

              toast: {
                value: true,
                color:'success',
                msg: 'Success'
              },
              toast_error: {
                value: true,
                color:'error',
                msg: 'Something when wrong!'
              }
        }
    },
    methods:{
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
          },
        AddItem(){
          if(this.$refs.form.validate() == true){
            this.Submit();
          }
        },
        Submit(){
          this.loading = true;
          this.$admin.post('add-water-truck', this.truck)
              .then(res =>{
                  if(res.data.success == true){
                      setTimeout(()=>{
                          this.loading = false;
                          this.CloseModalAdd();
                          this.truck={},
                          this.getData();
                          this.reset();
                          this.$store.commit("Toast_State",this.toast);
                      },1000);
                  }
              }).catch(error=>{
                  this.loading = false;
                  this.$store.commit("Toast_State",this.toast_error);
                  this.getData();
                  if(error.response.status == 422){
                      var obj = error.response.data.errors;
                      for (let [key,truck] of Object.entries(obj)){
                          this.server_errors[key] = truck[0];
                      }
                  }
              });
        },
        CloseModalAdd() {
          this.truck={},
          this.reset();
            this.$store.commit("modalAdd_State", false);
          },
        OpenModalEdit(item) {
            this.truck_edit = item;
            this.$store.commit("modalEdit_State", true);
          },

          UpdateItem(){
            if(this.$refs.form.validate() == true){
          this.loading = true;
          //this.truck_edit.driverId = this.factory_edit.driverUser;

          this.$admin.put('edit-water-truck/'+this.truck_edit.id,{
            license_plate:this.truck_edit.license_plate,
            phone: this.truck_edit.phone
          })
              .then(res =>{
                  if(res.data.success == true){
                      setTimeout(()=>{
                          this.loading = false;
                          this.CloseModalEdit();
                          this.truck_edit={};
                          this.reset();
                          this.getData();
                          this.$store.commit("Toast_State",this.toast);
                      },300);
                  }
              }).catch(error=>{
                  this.loading = false;
                  this.$store.commit("Toast_State",this.toast_error);
                  this.getData();
                  if(error.response.status == 422){
                      var obj = error.response.data.errors;
                      for (let [key,truck] of Object.entries(obj)){
                          this.server_errors[key] = truck[0];
                      }
                  }
              });
            }
          },

          CloseModalEdit() {
            this.truck_edit={};
            this.reset();
            this.$store.commit("modalEdit_State", false);
          },

          getData(){
            this.TableLoading =true;
              this.$admin.get('list-water-truck').then(res => {
                setTimeout(() => {
                    this.trucks = res.data.trucks;
                    this.TableLoading =false;
                }, 100);
            }).catch(() => {
              this.TableLoading =false;
              this.$store.commit("Toast_State",this.toast_error);
            });
          },

          closeDelete() {
          this.$store.commit("modalDelete_State", false);
        },
          deleteItem(id) {
            this.truckID = id;
            this.$store.commit("modalDelete_State", true);
          },
          deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('delete-water-truck/'+ this.truckID).then(res => {
                if(res.data.success == true){
                    setTimeout(() =>{
                        this.getData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State",this.toast);
                    },1000);
                }
            }).catch(()=>{
                this.getData();
                this.$store.commit("modalDelete_State", false);
                this.loading = true;
                this.$store.commit("Toast_State",this.toast_error);
            })
          },
           reset() {
            this.$refs.form.reset();
          },
    },
    watch:{
      'truck.email':function(){
        this.server_errors.email = '';
    },
      'truck.license_plate':function(){
        this.server_errors.license_plate = '';
    },
    'truck.phone':function(){
      this.server_errors.phone = '';
  },

  'truck_edit.email':function(){
    this.server_errors.email = '';
},
      'truck_edit.license_plate':function(){
        this.server_errors.license_plate = '';
    },
    'truck_edit.phone':function(){
      this.server_errors.phone = '';
    },
    },
    created() {
        this.getData();
    },
}
