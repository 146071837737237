<template>
  <div>
    <v-row justify="end">
      <div class="my-2">
        <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="trucks"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.created_at="{ item }">
        <div>
          {{ item.created_at | formatDate }}
        </div>
      </template>
      <!--Action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add Truck</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Truck ID*"
                      required
                      v-model="truck.license_plate"
                      :rules="idRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.license_plate }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Phone*"
                      required
                      v-model="truck.phone"
                      :rules="phoneRules"
                      type="number"
                      class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalAdd()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="AddItem()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update Truck</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Truck ID*"
                      required
                      v-model="truck_edit.license_plate"
                      :rules="idRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.license_plate }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Phone*"
                      required
                      v-model="truck_edit.phone"
                      :rules="phoneRules"
                      type="number"
                      class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalEdit()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="UpdateItem()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
import manage from "@/mixins/truck/manage";
export default {
  mixins: [manage],
  metaInfo() {
    return {
      title: `ຂໍ້ມູນລົດຈັກສົ່ງ`,
      meta: [
        { name: 'description', content: 'ຂໍ້ມູນລົດຈັກສົ່ງ'},
      ]
    }
  },
};
</script>

<style>
</style>